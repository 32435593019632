<template>
  <div
    :style="{ transform: `translateY(${Math.round(y / 2)}px)` }"
    class="background"
  >
    <PixelAnimation
      v-for="(pixel, index) in pixels"
      :id="pixel.key"
      :key="pixel.key"
      :color="pixel.color"
      :delay="pixel.delay"
      class="pixel"
      :class="`pixel--x-${pixel.x}`"
      @complete="onComplete(index % pixelData.length)"
    />
  </div>
</template>

<script lang="ts" setup>
import { useWindowScroll } from '@vueuse/core'
import { random, range, sample, uniqueId } from 'lodash-es'
import { Ref } from 'vue'

import { PixelColor, pixelColors } from '@/components/PixelAnimation.vue'

const { y } = useWindowScroll()
const height = inject<Ref<number>>('pageHeight', ref(1))

const pixelData = ref<
  { color: PixelColor; key: string; x: number | string; delay?: number }[]
>([
  { color: 'green', key: uniqueId(), x: 1, delay: random(0, 2000, false) },
  { color: 'pink', key: uniqueId(), x: 2, delay: random(0, 2000, false) },
  { color: 'blue', key: uniqueId(), x: 3, delay: random(0, 2000, false) },
  { color: 'green', key: uniqueId(), x: 4, delay: random(0, 2000, false) },
  { color: 'blue', key: uniqueId(), x: 5, delay: random(0, 2000, false) },
  { color: 'pink', key: uniqueId(), x: 6, delay: random(0, 2000, false) },
  { color: 'green', key: uniqueId(), x: 7, delay: random(0, 2000, false) },
  { color: 'blue', key: uniqueId(), x: 8, delay: random(0, 2000, false) },
  { color: 'violet', key: uniqueId(), x: 9, delay: random(0, 2000, false) },
  { color: 'green', key: uniqueId(), x: 10, delay: random(0, 2000, false) },
  { color: 'violet', key: uniqueId(), x: 11, delay: random(0, 2000, false) },
  { color: 'violet', key: uniqueId(), x: 12, delay: random(0, 2000, false) }
])

const pixels = computed(() =>
  range(Math.max(Math.ceil(height.value / 6000), 1)).flatMap(
    () => pixelData.value
  )
)

const onComplete = (index: number) => {
  pixelData.value[index] = {
    color: sample(pixelColors),
    key: uniqueId(),
    x: random(1, pixelData.value.length, false),
    delay: random(0, 500, false)
  }
}
</script>

<style lang="scss" scoped>
.background {
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(var(--page-height) / 2);
  overflow: hidden;
  transition: transform 0.15s ease-out;
}

.pixel {
  position: absolute;
  width: rem(55px);
  height: rem(59.59px);

  @include media-up(lg) {
    width: rem(95.59px);
    height: rem(103.58px);
  }

  &--x-1 {
    left: rem(122px);

    @include media-up(lg) {
      right: rem(279px);
      left: unset;
    }
  }

  &--x-2 {
    left: rem(-8px);

    @include media-up(lg) {
      left: rem(-65px);
    }
  }

  &--x-3 {
    left: rem(129px);

    @include media-up(lg) {
      left: rem(383px);
    }
  }

  &--x-4 {
    left: rem(86px);

    @include media-up(lg) {
      left: rem(166px);
    }
  }

  &--x-5 {
    right: rem(-16px);

    @include media-up(lg) {
      right: rem(-2px);
    }
  }

  &--x-6 {
    right: rem(83px);

    @include media-up(lg) {
      right: rem(610px);
    }
  }

  &--x-7 {
    left: rem(-24px);

    @include media-up(lg) {
      left: rem(-31px);
    }
  }

  &--x-8 {
    left: rem(-24px);

    @include media-up(lg) {
      left: rem(-20px);
    }
  }

  &--x-9 {
    left: rem(242px);
  }

  &--x-10 {
    right: rem(36px);

    @include media-up(lg) {
      right: rem(293.41px);
    }
  }

  &--x-11 {
    right: rem(64.79px);

    @include media-up(lg) {
      right: rem(489.41px);
    }
  }

  &--x-12 {
    right: rem(116.79px);
  }

  &:nth-child(12n + 1) {
    top: rem(172px);

    @include media-up(lg) {
      top: rem(183px);
    }
  }

  &:nth-child(12n + 2) {
    top: rem(315px);

    @include media-up(lg) {
      top: rem(323px);
    }
  }

  &:nth-child(12n + 3) {
    top: rem(736px);

    @include media-up(lg) {
      top: rem(687px);
    }
  }

  &:nth-child(12n + 4) {
    top: rem(1262px);

    @include media-up(lg) {
      top: rem(1343px);
    }
  }

  &:nth-child(12n + 5) {
    top: rem(1650px);

    @include media-up(lg) {
      top: rem(1400px);
    }
  }

  &:nth-child(12n + 6) {
    top: rem(1769px);

    @include media-up(lg) {
      top: rem(1654px);
    }
  }

  &:nth-child(12n + 7) {
    top: rem(2210px);

    @include media-up(lg) {
      top: rem(1985px);
    }
  }

  &:nth-child(12n + 8) {
    top: rem(2708px);

    @include media-up(lg) {
      top: rem(2803.23px);
    }
  }

  &:nth-child(12n + 9) {
    top: rem(1373px);

    @include media-up(lg) {
      display: none;
    }
  }

  &:nth-child(12n + 10) {
    top: rem(2439px);

    @include media-up(lg) {
      top: rem(2266px);
    }
  }

  &:nth-child(12n + 11) {
    top: rem(2838px);
    right: rem(64.79px);

    @include media-up(lg) {
      top: rem(1149px);
      right: rem(489.41px);
    }
  }

  &:nth-child(12n + 12) {
    top: rem(2898px);

    @include media-up(lg) {
      display: none;
    }
  }
}
</style>

<template>
  <Component
    :is="tag"
    ref="root"
    class="nav-item"
    :class="{ 'nav-item--with-dropdown': item.items?.length }"
    @mouseenter="handleMouseEnter"
    @mouseleave="hideSubmenu"
  >
    <BaseLink
      v-if="item.link"
      :link="{ ...item.link, label: item.label }"
      class="lnk t-caption"
    >
      <span class="label">{{ item.label }}</span>
    </BaseLink>
    <template v-else>
      <button
        class="lnk t-caption"
        data-is-dropdown="true"
        :aria-describedby="
          item.items?.length ? slugify(`dropdown ${item.label}`) : undefined
        "
        :aria-expanded="submenuIsOpen"
        @focus="showSubmenu"
        @blur="handleBlur"
      >
        <span class="label">{{ item.label }}</span>
        <ArrowDown class="arrow-icon" aria-hidden="true" role="img" />
      </button>
      <NavigationDropdown
        v-if="item.items?.length"
        ref="dropdown"
        :item="item"
        class="dropdown"
      >
        <template #default="{ subItem, elClass }">
          <NavigationSubItem
            :class="elClass"
            :item="subItem"
            data-is-dropdown="true"
            @click="handleLinkClick"
            @blur="handleBlur"
            @focus="showSubmenu"
          />
        </template>
      </NavigationDropdown>
    </template>
  </Component>
</template>

<script setup lang="ts">
import ArrowDown from '@/assets/icons/arrow-down.svg?component'
import { PublicMenuItem } from '@/service/__generated-api'

const props = withDefaults(
  defineProps<{ tag?: 'li' | 'div'; item: PublicMenuItem }>(),
  {
    tag: 'li'
  }
)
const submenuIsOpen = ref(false)
const root = ref<HTMLElement>()
const showSubmenu = () => {
  if (!props.item.items?.length || !root.value) return
  const dropdown = root.value.querySelector<HTMLUListElement>('.dropdown')
  if (!dropdown) return
  const height =
    dropdown.clientHeight ||
    dropdown.offsetHeight ||
    dropdown.getBoundingClientRect().height
  document.documentElement.style.setProperty(
    '--navigation-bg-height',
    `${height / 16}rem`
  )
  document.documentElement.style.setProperty('--navigation-bg-translate', '0%')
  submenuIsOpen.value = true
}

const hideSubmenu = () => {
  if (
    document.activeElement instanceof HTMLElement &&
    document.activeElement.dataset.isDropdown
  )
    document.activeElement.blur()
  document.documentElement.style.setProperty(
    '--navigation-bg-translate',
    '-100%'
  )
  submenuIsOpen.value = false
}

const handleLinkClick = () => {
  if (document.activeElement instanceof HTMLElement)
    document.activeElement.blur()
  root.value?.classList.add('break-hover')
  setTimeout(() => root.value?.classList.remove('break-hover'), 200)
}

const handleBlur = ({ relatedTarget }: FocusEvent) => {
  if (
    !(relatedTarget instanceof HTMLElement) ||
    !relatedTarget.dataset.isDropdown
  )
    hideSubmenu()
  else showSubmenu()
}

const handleMouseEnter = () => {
  if (
    document.activeElement instanceof HTMLElement &&
    document.activeElement.dataset.isDropdown
  ) {
    document.activeElement.blur()
  }
  showSubmenu()
}
</script>

<style lang="scss">
.route-change .nav-item--with-dropdown,
.route-change .nav-item--with-dropdown:hover .dropdown,
.hidden-header .nav-item--with-dropdown,
.hidden-header .nav-item--with-dropdown:hover .dropdown {
  pointer-events: none !important;
}
</style>

<style lang="scss" scoped>
.nav-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  &:hover:not(.break-hover),
  &:focus-within {
    .label::before {
      opacity: 1;
      transform: scaleX(1);
    }

    .arrow-icon {
      transform: rotateX(-180deg);
    }

    .dropdown {
      pointer-events: all;
      opacity: 1;
      transition:
        transform $dynamic-transition,
        opacity 0.5s 0.3s;
      transform: translateY(0%);
    }
  }
}

.lnk {
  @include center-content;
  gap: rem(4px);
  height: 100%;
  padding: rem(8px) rem(10px);
  margin: rem(-8px) rem(-10px);

  cursor: pointer;

  .label {
    position: relative;

    &::before {
      position: absolute;
      top: rem(-8px);
      left: 0;
      width: 100%;
      content: '';
      border-bottom: $base-border;
      opacity: 0;
      transition:
        opacity $transition,
        transform 0.4s ease-out;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }
  }

  &:focus-visible .label {
    @include focus-outline;
  }
}

.arrow-icon {
  margin-top: -0.05em;
  transition: transform $transition;
}

.dropdown {
  pointer-events: none;
  opacity: 0;
  transition:
    transform $dynamic-transition,
    opacity 0.3s;
  transform: translateY(-100%);
}
</style>

<template>
  <NavigationBar
    :menu="menu"
    :active-menu="isActive"
    @open="openMenu"
    @close="closeMenu"
  />
</template>

<script setup lang="ts">
import { sortBy } from 'lodash-es'

const menu = await useAsyncData('websiteMenu', () =>
  useApi().publicMenuMainRetrieve({
    fetchKeys: {
      items: true,
      primary_cta_button: true,
      secondary_cta_button: true,
      festival_date: true,
      festival_place: true
    }
  })
).then(({ data }) =>
  computed(() => {
    if (!data.value) return data.value
    return {
      ...data.value,
      items: sortBy(data.value.items, 'is_after_divider')
    }
  })
)

const isActive = ref(false)

const openMenu = () => {
  isActive.value = true
}
const closeMenu = () => {
  isActive.value = false
}
</script>

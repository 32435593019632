<template>
  <div v-if="socialLinks.length">
    <ul class="social" role="list">
      <li v-for="(social, index) in socialLinks" :key="index">
        <a
          :href="social.url"
          target="_blank"
          rel="noopener noreferrer"
          class="social-button"
          :aria-label="`${social.name} - ${t('a11y.opening-in-new-tab')}`"
        >
          <Component :is="getIcon(social.name)" aria-hidden="true" role="img" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { get } from 'lodash-es'

import { useWebsiteSettings } from '@/state/websiteSettings'

const settings = await useWebsiteSettings()

const getSocialUrl = (social: string): string =>
  get(settings.value, `${social}_url`, '')

const socialLinks = [
  'linkedin',
  'facebook',
  'instagram',
  'youtube',
  'spotify',
  'twitter'
]
  .map(social => ({
    name: social,
    url: getSocialUrl(social)
  }))
  .filter(social => social.url)

const getIcon = (icon: string) =>
  defineAsyncComponent(() => import(`../../assets/icons/${icon}.svg?component`))

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.social {
  display: flex;
  gap: rem(32px);
  align-items: center;
}

.social-button {
  @include focus-visible;
  // prevent inline behavior
  display: flex;
}
</style>

<template>
  <header
    ref="header"
    :class="{
      fixed,
      visible,
      'logo-loaded': logoLoaded,
      'no-transition': noTransition,
      'active-menu': activeMenu
    }"
    class="navigation-bar"
  >
    <div id="scroll-header" class="scroll-header">
      <nav id="nav" class="grid">
        <ul class="nav-elements" role="list">
          <li class="logo-wrap">
            <Logo @load="logoLoaded = true" @click="$emit('close')" />
          </li>

          <ClientOnly>
            <NavigationDesktopLinks v-if="isDesktop" :items="menu?.items" />
            <template #fallback>
              <NavigationDesktopLinks :items="menu?.items" />
            </template>
          </ClientOnly>

          <li class="right-side">
            <div class="navigation-bg navigation-bg--under" />
            <div class="buttons">
              <BaseButton
                v-if="menu?.primary_cta_button"
                :base-link="menu.primary_cta_button.link"
                color="primary"
                class="primary-button xl-up"
              />
              <BaseButton
                v-if="menu?.secondary_cta_button"
                :base-link="menu.secondary_cta_button.link"
                color="secondary"
                class="xl-up"
              />
            </div>
            <LanguageSwitcher />
            <NavigationHamburger
              :active="activeMenu"
              class="xl-down"
              @click="handleClick"
            />
          </li>
        </ul>
        <ClientOnly>
          <NavigationSidebar
            v-if="!isDesktop"
            :menu="menu"
            :active="activeMenu"
            @close="$emit('close')"
          />
        </ClientOnly>
      </nav>
      <div class="grid">
        <div class="info">
          <BaseImage
            v-if="settings?.secondary_logotype"
            :image="settings.secondary_logotype"
            fit="fit"
            class="logo--secondary xl-up"
          />
          <div class="date" v-html="nbsp(menu?.festival_date)" />
          <div class="place" v-html="nbsp(menu?.festival_place)" />
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useMediaQuery } from '@vueuse/core'

import LanguageSwitcher from '@/components/navigation/LanguageSwitcher.vue'
import { PublicMainMenu } from '@/service/__generated-api'
import { useWebsiteSettings } from '@/state/websiteSettings'

const props = withDefaults(
  defineProps<{ menu: Partial<PublicMainMenu> | null; activeMenu: boolean }>(),
  {
    activeMenu: false
  }
)

const emit = defineEmits(['open', 'close'])
const logoLoaded = ref(false)
const fixed = ref(false)
const visible = ref(true)
const noTransition = ref(false)
const isDesktop = useMediaQuery('(min-width: 1340px)')
const settings = await useWebsiteSettings()

const header = ref<HTMLDivElement>()
const { open, close } = useModal(header)

watch(
  () => props.activeMenu,
  isActive => {
    if (isActive) open()
    else close()
  }
)

const handleClick = () => emit(props.activeMenu ? 'close' : 'open')

onMounted(() => {
  let pageYOffset = window.pageYOffset
  window.addEventListener('scroll', () => {
    const currentPageYOffset = window.pageYOffset
    const navHeight =
      document.querySelector('#scroll-header')?.clientHeight || 177
    if (!currentPageYOffset) {
      // hits start of a page
      visible.value = true
      noTransition.value = true
      fixed.value = false
      document.body.classList.remove('hidden-header')
      document.body.classList.remove('fixed-header-visible')
    } else if (
      currentPageYOffset < pageYOffset &&
      currentPageYOffset > navHeight
    ) {
      // scrolling up
      visible.value = true
      noTransition.value = false
      fixed.value = true
      document.body.classList.remove('hidden-header')
      document.body.classList.add('fixed-header-visible')
    } else if (currentPageYOffset > navHeight) {
      // scrolling down
      visible.value = false
      noTransition.value = !fixed.value
      fixed.value = true
      document.body.classList.add('hidden-header')
      document.body.classList.remove('fixed-header-visible')
    }
    pageYOffset = currentPageYOffset
  })
})

useHead({
  noscript: [
    {
      key: 'navigation',
      innerHTML: `<style>
          .navigation-bar {
            opacity: 1 !important;
          }

          .navigation-bar .info .date, .navigation-bar .info .place {
            opacity: 1 !important;
          }
        </style>`
    }
  ]
})
</script>

<style lang="scss" scoped>
.navigation-bar {
  position: relative;
  z-index: z(header-navigation);
  min-height: $nav-height;

  opacity: 0;
  transition:
    opacity $transition,
    z-index 0s $dynamic-transition;

  &.active-menu {
    z-index: z(active-header-navigation);
    transition:
      opacity $transition,
      z-index 0s;
  }

  &.logo-loaded {
    opacity: 1;
  }
}

.scroll-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;

  grid-template-rows: $nav-top-section-height 1fr;
  min-height: inherit;
  pointer-events: none;

  background-color: $light-grey;
  opacity: 0;
  transition: opacity 0.3s;

  .fixed & {
    position: fixed;
  }

  .navigation-bar:focus-within &,
  .visible &,
  .active-menu & {
    pointer-events: auto;
    opacity: 1;
  }

  .no-transition & {
    transition: none;
  }
}

.nav-elements,
.info {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
  z-index: 1;

  &::after {
    position: absolute;
    right: var(--grid-space-around);
    left: var(--grid-space-around);
    pointer-events: none;
    content: '';
    border-bottom: $base-border;
  }
}

.info {
  display: flex;
  justify-content: space-between;

  &::after {
    z-index: -1;
  }
}

.nav-elements {
  z-index: 3;
  display: grid;
  grid-template-areas: 'logo . links . right-side';
  grid-template-columns: rem(70px) rem(41px) max-content 1fr max-content;
  align-items: center;
  height: 100%;
  padding: rem(32px) 0;

  @include media-up(xl) {
    grid-template-columns: rem(80px) rem(45px) max-content 1fr max-content;
  }

  &::after {
    top: $nav-top-section-height;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  padding: rem(12px) 0 rem(16px);

  .date,
  .place {
    @include t-caption-small;
    @include font-size(
      (
        lg: 22px 0.90909,
        xxl: 26px
      )
    );
  }

  .date {
    white-space: nowrap;
  }

  .place {
    max-width: rem(172px);
    text-align: right;

    @include media-up(md) {
      max-width: rem(225px);
    }

    @include media-up(lg) {
      max-width: rem(631px);
    }

    @include media-up(xl) {
      max-width: rem(750px);
    }
  }

  &::after {
    bottom: 0;
  }
}

.navigation-bg {
  @include fill-space;
  z-index: -1;
  height: var(--navigation-bg-height, 100%);
  background-color: $orange;
  transition:
    transform $dynamic-transition,
    height $transition;
  transform: translateY(var(--navigation-bg-translate, -100%));
}

.logo-wrap {
  grid-area: logo;
}

.links-wrap {
  grid-area: links;
}

.info .logo--secondary {
  width: fit-content;
  height: rem(21px);

  @include media-up(xxl) {
    height: rem(27px);
  }

  ~ .date,
  ~ .place {
    @include media-up(lg) {
      opacity: 0;
      transition: opacity $transition;
    }
  }

  &.loaded {
    ~ .date,
    ~ .place {
      opacity: 1;
    }
  }
}

.right-side {
  @include center-content;
  grid-area: right-side;
  gap: rem(34px);

  .primary-button {
    mix-blend-mode: difference;
  }
}

.buttons {
  display: flex;
  gap: rem(14px);
}
</style>
